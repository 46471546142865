.details-of-program {
  font-family: 'Roboto Slab', serif;
  font-style: normal;
  margin: 20px;

  &--title {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
    margin: 0 0 20px 20px;
  }

  .accordion {
    padding: 0 10px;

    .accordion-item, .accordion-header > button {
      border-radius: 15px 15px 0 0;
    }

    .accordion-header {
      button {
        padding: 10px 13px 10px 15px;
        justify-content: space-between !important;

        &:focus {
          border-color: unset;
          box-shadow: none;
        }

        &::after {
          background-image: url("../../assets/collapse-arrow-down.svg");
          margin-left: unset;
        }

        &.accordion-button:not(.collapsed) {
          color: unset;
          background-color: unset;
          box-shadow: none;
        }
      }

      img {
        padding: 0 12px;
        margin-left: auto;
      }
    }

    table {
      th {
        font-size: 14px;
      }

      td {
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .header {
    background-color: white;
    padding: 0 0px;

    .study {
      padding-right: 10px;

      h2 {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #23271f;
        margin-bottom: 5px;
      }

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 132.38%;
        color: #52554f;
      }
    }

    .icons {
      gap: 10px;
    }
  }

  .scores {
    height: 32px;

    .program-score,
    .path-score {
      width: 50%;
      padding: 7px 17px 9px 15px;

      .score {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-size: 13px;
      }
    }

    img {
      margin-left: 5px;
      margin-right: 5px;
    }

    .program-score {
      width: 50%;
      background-color: #548235;

      label {
        color: #ffffff;
        font-size: 12px;
      }

      .score {
        line-height: 100%;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .path-score {
      width: 50%;
      background-color: #f5c342;

      label {
        color: #464444;
        font-size: 12px;
      }

      .score {
        line-height: 125%;
        color: #464444;
        font-weight: 800;
      }
    }
  }

  .money {
    background-color: white;
    min-height: 32px;
    border-top: none;
    border-bottom: 1px solid rgba(153, 154, 152, 0.1);

    .price {
      width: 50%;
      padding: 7px 10px 9px 15px;

      label {
        font-size: 12px;
      }

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;

        color: #283c57;
        padding-left: 10px;
      }
    }
  }

  &--program-scool {
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #23271F;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 132.38%;
      color: #52554F;
    }
  }

  &--wrapper-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #FFFFFF;

    .value-of-section {
      background: #548235 !important;
      color: #F0F5FF;
      font-size: 31px;
      font-weight: 500;
      height: 40px;
      line-height: 50%;
      margin-right: 10px;
      overflow-wrap: break-word;
      width: 100px;
    }

    .label-of-section {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #000000;
    }

    .info-of-section {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #52554F;
    }

    &--logo-and-path-score {
      display: flex;
      gap: 10px;

      img {
        width: 76px;
        height: 40px;
      }

      .value-of-section {
        width: 80px;
        background: #52554F !important;
        color: #F6C342;
      }
    }

    &--program-score, &--likelihood-of-admission, &--geographic-preferences, &--interest-in-major {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
    }

    &--likelihood-of-admission {
      .value-of-section {
        font-size: 13px;
        line-height: 100%;
        background-color: #52554F !important;
      }
    }

    &--geographic-preferences {
      .value-of-section {
        font-size: 13px;
        line-height: 250%;
      }

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 80%;
        color: #f0f5ff;

        padding: 10px;
      }

      .checkmark {
        position: relative;
        top: -6px;
        right: -8px;
      }
    }

    &--geographic-preferences, &--interest-in-major {
      .value-of-section {
        background-color: #52554F !important;
      }
    }

    &--interest-in-major {
      margin-bottom: 10px;
      max-width: 100%;
      overflow: wrap;

      .value-of-section {
        color: #F6C342 !important;
      }

      .interests {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        max-width: 100%;
      }

      .matches {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        max-width: 100%;
      }
    }
  }
}
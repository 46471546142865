@import 'variables';

.activities {
  padding: 10px 30px;
  height: 100%;

  .description {
    margin-bottom: 10px;
  }

  h1 {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #283c57;

    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;

    span {
      color: #548235;
    }
  }

  .selected-activities {
    .headers {
      width: 100%;
      margin-bottom: 5px;

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #818380;
      }
    }

    .activity-row {
      margin-bottom: 10px;

      input {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #548235;

        width: 72px;
        margin-left: 10px;
        background-color: inherit;
        text-align: center;
        border: 1px solid rgba(40, 60, 87, 0.1);
        border-radius: 10px;
        box-shadow: none;

        &.invalid {
          color: #C93F12;
        }
      }

      .delete-button {
        margin-left: 10px;
      }
    }
  }

  .add-button {
    height: 42px;
    width: 42px;
    align-self: center;
  }

  .space-div {
    padding-top: 40px;
  }
}

.activity-select {
  h1 {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #23271f;

    text-align: center;
    margin: 20px 0;
  }

  .categories {
    gap: 5px;
    flex-flow: row wrap;
    margin: 0 10px 10px;

    .category {
      gap: 10px;

      h2 {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        text-transform: capitalize;
        margin-bottom: 0;
        margin-top: 10px;

        &.academic {
          color: $academic-color;
        }

        &.athletic {
          color: $athletic-color;
        }

        &.arts {
          color: $arts-color;
        }

        &.community {
          color: $community-color;
        }

        &.scientific {
          color: $scientific-color;
        }
      }
    }
  }
}

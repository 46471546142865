$steps-height: 120px;

.user-info {
  .header {
    height: $steps-height;
    padding: 20px 40px 0 40px;
    border-bottom: 1px solid rgba(35, 39, 31, 0.1);

    h1 {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      color: #548235;
    }

    .navigation-panel {
      padding-top: 7px;
    }
  }

  .content {
    height: calc(100% - $steps-height);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  text-align: center;

  .logo {
    width: 300px;
    margin-bottom: 20px;
  }

  .title {
    color: #283C57;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    font-style: normal;
  }

  label {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin-top: 20px;
  }

  .feedback-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #283C57;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
  }

  .feedback-button:hover {
    background-color: #1E2C40;
  }

}
.swiper {
  height: 100%;

  .school-major-container {
    .title {
      padding: 0;

      h1 {
        margin-bottom: 15px;
        margin-left: 30px;
      }

      form {
        padding: 0 30px;

        .search-field {
          width: 100%;
          margin-bottom: 10px;
        }

        .form-control {
          border-left: none !important;
          border-right: none !important;
        }

        .input-group-text {
          background-color: white;
          font-size: 30px;
          color: #52554f;
          cursor: pointer;

          img {
            height: 16px;
            width: 16px;
          }

          &.search-icon {
            border-right: none !important;
            padding-right: 0;
          }

          &.clear-icon {
            border-left: none !important;
          }
        }
      }

      .popular-programs-line {
        padding: 0 20px 15px 30px;

        label {
          font-family: 'Roboto Slab', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #23271F;
        }

        img {
          height: 21px;
          width: 21px;
        }
      }
    }

    .programs {
      height: calc(100% - 200px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .swiper-pagination {
    background-color: #f0f5ff;
    bottom: 0;

    .swiper-pagination-bullet {
      height: 10px;
      width: 10px;
      background-color: #f6c342;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: #548235;
      }
    }
  }
}

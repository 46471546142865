.introduction {
  margin-bottom: 20px;

  h1 {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 100%;
    color: #000000;

    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
  }

  .form-label {
    padding-top: 15px;
  }

  #roleSelect {
    transform: translate3d(0px, -100%, 0px) !important;
  }
}

.cost-and-geography {
  padding-bottom: 30px;

  h2 {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #23271f;

    margin-top: 10px;
    margin-bottom: 0;
  }

  .form-label {
    padding-top: 15px;
  }

  .cost-wrapper {
    padding-top: 20px;

    .cost {
      margin-top: 20px;

      .less,
      .most {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;
      }

      .less {
        color: #000000;
      }

      .most {
        color: #548235;
        padding-left: 10px;
      }

      input[type='range'] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
      }

      input[type='range'] {
        background: linear-gradient(270deg, #548235 17.01%, rgba(84, 130, 53, 0) 102.07%);
        height: 6px;
        border-radius: 3px;

        &::-webkit-slider-runnable-track {
          background: inherit;
        }

        &::-webkit-slider-thumb {
          background-color: #548235;
          height: 18px;
          width: 18px;
          margin-top: -5px;
        }
      }
    }
  }

  .all-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .all-checkbox label {
    margin-left: 10px;
  }
}

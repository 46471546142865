$title-height: 46px;

.programs-container.list {
  .title {
    height: $title-height;
  }

  .programs {
    height: calc(100% - $title-height);
    overflow-y: auto;
  }
}

$padding-top: 15px;

.programs-container {
  padding-top: $padding-top;
  height: 100%;

  .title {
    padding-left: 30px;
    padding-right: 20px;

    h1 {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      margin-bottom: -25px;
    }

    label {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }

    img {
      height: 26px;
    }
  }

  .programs {
    padding: 0 20px;

    .see {
      padding-top: 5px;

      a {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #283c57;

        text-decoration: none;
      }
    }
  }

  .saved-programs {
    padding-bottom: 20px;

    .title {
      padding-top: 40px;
    }
  }
}

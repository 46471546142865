@import 'variables';

.class-chip {
  height: 40px;
  width: 132px;
  border: 1px solid;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 7px 9px;

  border-radius: 10px;

  span {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;

    color: #23271F;
  }

  &.selected:not(.big) {
    span {
      font-weight: 400;
    }
  }

  &.small {
    height: 40px;
    width: 40px;
  }

  &.big {
    height: 47px;
    width: 188px;
    min-width: 176px;

    span {
      font-size: 24px;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }

  &.science {
    border-color: $science-color;

    &.selected {
      background-color: $science-color;
    }
  }

  &.math {
    border-color: $math-color;

    &.selected {
      background-color: $math-color;
    }
  }

  &.social {
    border-color: $social-color;

    &.selected {
      background-color: $social-color;
    }
  }

  &.english {
    border-color: $english-color;

    &.selected {
      background-color: $english-color;
    }
  }

  &.arts {
    border-color: $arts-color;

    &.selected {
      background-color: $arts-color;
    }
  }

  &.health {
    border-color: $health-color;

    &.selected {
      background-color: $health-color;
    }
  }
}

.what-is {
  padding: 20px 30px;
  height: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;

    label {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      color: #23271f;
    }
  }

  .description {
    color: #3a3e37;

    &:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
}

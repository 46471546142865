$footer-height: 70px;

.page-content {
  height: calc(100% - $footer-height);
  overflow-y: auto;
}

footer {
  height: $footer-height;
  border-top: 1px solid rgba(35, 39, 31, 0.1);
  padding: 0 28px;

  .back, .skip, .submit {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-size: 24px;
    line-height: 100%;
    text-decoration: none;
  }

  .back {
    font-weight: 400;
    color: #283C57 !important;
  }

  .skip {
    font-weight: 300;
    color: #52554F !important;
    margin-left: auto;
  }

  .submit {
    font-weight: 500;
    color: #548235 !important;
    margin-left: auto;
  }
}

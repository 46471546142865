.auth {
  overflow-y: auto;

  .header {
    padding-top: 50px;
    width: 70%;

    h1 {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 55px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.015em;
      color: #23271f;
    }
  }

  form {
    .email {
      margin-bottom: 10px;
    }

    .password {
      margin-bottom: 10px;

      .form-control {
        border-right: none !important;
      }

      .input-group-text {
        border-left: none !important;
        background-color: white;
        font-size: 30px;
        color: #52554f;
        cursor: pointer;
      }
    }

    .remember-me-label {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #23271f;

      margin-left: 20px;
      cursor: pointer;
    }

    .forgot-password {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: right;
      color: #548235;

      text-decoration: none;
    }

    .main-button {
      margin-top: 40px;
      margin-bottom: 20px;
      background-color: #548235 !important;

      &:active {
        background-color: #48702e !important;
      }
    }

    .sign-in-label {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #23271f;
      display: block;

      margin-bottom: 20px;

      a {
        text-decoration: none;
        color: #548235;
      }
    }
  }

  .footer {
    padding-bottom: 10px;
    width: 70%;

    h2 {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 150%;
      text-align: center;
      color: #23271f;
    }

    label {
      color: #6a6c68;
      display: block;
      font-family: 'Roboto Slab', serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 5px;
      text-align: center;
    }

    .links {
      gap: 10px;
      padding-top: 20px;

      a {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #23271F;
      }
    }
  }
}

@import '../WhatIs';

.what-is.program-score {
  background: radial-gradient(267.71% 93.9% at 50% 5.02%, #a7c493 16.74%, #f0f5ff 81.89%);

  .school-major {
    width: 100%;
    align-items: baseline;
    margin-bottom: 10px;
    margin-top: 30px;

    .block {
      height: 60px;
      width: calc((100% - 26px) / 2);
      border-radius: 10px;
      border: 1px solid #818380;
      background-color: white;

      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.08em;
      color: #23271f;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plus {
      font-size: 26px;
      padding: 0 5px;
    }
  }

  .program-score-row {
    margin-bottom: 10px;

    .left-block {
      margin-right: 5px;
    }

    .right-block {
      margin-left: 5px;
    }

    .left-block,
    .right-block {
      width: 50%;
      height: 55px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;

      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 90%;
      color: #23271f;

      img {
        margin: 0 10px;
      }
    }
  }

  .arrow-down-container {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid rgba(153, 154, 152, 0.22);
    background-color: white;

    img {
      margin-top: 3px;
    }
  }

  .main-button {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #548235 !important;

    &:active {
      background-color: #48702e !important;
    }
  }

  .explainer {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #52554F;
  }
}

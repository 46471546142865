@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.round {
  position: relative;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;

    &:after {
      border: 2px solid #548235;
      border-top: none;
      border-right: none;
      content: "";
      height: 5px;
      left: 2px;
      opacity: 0;
      position: absolute;
      top: 4px;
      transform: rotate(-45deg);
      width: 10px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;

    & + label {
      border-color: #548235;
    }

    &:checked + label:after {
      opacity: 1;
    }
  }
}

$footer-height: 70px;

.base {
  .page {
    height: calc(100% - $footer-height);
    overflow: auto;
  }

  footer {
    height: $footer-height;
    border-top: 1px solid rgba(40, 60, 87, 0.1);
    padding: 0 10%;
  }
}

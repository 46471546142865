@import 'react-bootstrap-typeahead/css/Typeahead.css';
@import 'swiper/css';
@import 'swiper/css/pagination';

html,
#root {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 850px;
  height: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
  @media only screen and (max-width: 1250px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  background-color: #f0f5ff !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.valid,
.error {
  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.valid {
  color: #548235;
}

.error {
  color: #c93f12;
}

.navigation-panel .navigation-icon {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #548235;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &.selected {
    background-color: #548235;
  }
}

form {
  padding: 0 20px;

  .form-control,
  .input-group-text {
    border: 1px solid #c8c8c8 !important;
    border-radius: 15px;
    height: 45px;
    box-shadow: none !important;

    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #23271f !important;

    &.invalid {
      border-color: #c93f12 !important;
    }
    
    &::placeholder {
      font-family: 'Roboto Slab', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #BEBEBE;

      padding-left: 5px;
    }
  }

  .rbt-input-hint {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .rbt-menu {
    border-radius: 15px;

    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #23271f !important;
  }

  label {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #6a6c68;

    margin-left: 10px;
    margin-top: 5px;
  }
}

.main-button {
  width: 80% !important;
  align-self: center !important;
  height: 60px !important;
  border-radius: 20px !important;
  border: none !important;

  font-family: 'Roboto Slab', serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 150% !important;
  text-align: center !important;
  letter-spacing: 0.08em !important;
  color: #ffffff !important;
}

.description {
  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #52554f;
}

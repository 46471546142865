.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.logo {
  width: 300px;
  margin-bottom: 20px;
}

.title {
  color: #283C57;
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Roboto Slab', serif;
  font-style: normal;
}
$user-section-height: 125px;

.user-section {
  height: $user-section-height;
  background-color: white;
  padding: 15px 20px;

  .name {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #283C57;

    margin-bottom: 10px;
  }

  label {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #52554F;

    &:first-of-type {
      margin-bottom: 5px;
    }
  }
}

.content {
  height: calc(100% - $user-section-height);
  overflow-y: auto;
}
.program {
  padding-bottom: 10px;

  label {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #23271f;
  }

  .label-bold {
    font-family: 'Roboto Slab', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    color: #23271f;
  }

  .header {
    border-radius: 15px 15px 0 0;
    background-color: white;
    border: 1px solid rgba(153, 154, 152, 0.1);
    padding: 10px 15px;

    .study {
      padding-right: 10px;

      h2 {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #23271f;
        margin-bottom: 5px;
      }

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 132.38%;
        color: #52554f;
      }
    }

    .icons {
      gap: 10px;
    }
  }

  .scores {
    height: 32px;

    .program-score,
    .path-score {
      width: 50%;
      padding: 7px 17px 9px 15px;

      .score {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-size: 16px;
      }
    }

    img {
      margin-left: 5px;
      margin-right: 5px;
    }

    .program-score {
      background-color: #548235;

      label {
        color: #ffffff;
      }

      .score {
        line-height: 100%;
        color: #ffffff;
        font-weight: 500;
      }
    }

    .path-score {
      width: 50%;
      background-color: #f5c342;

      label {
        color: #464444;
      }

      .score {
        line-height: 125%;
        color: #464444;
        font-weight: 800;
      }
    }
  }

  .money {
    background-color: white;
    border: 1px solid rgba(40, 60, 87, 0.2);
    border-top: none;
    border-bottom: none;
    min-height: 32px;

    .price {
      width: 50%;
      padding: 7px 10px 9px 15px;

      span {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;

        color: #283c57;
        padding-left: 10px;
      }
    }

    .graduates {
      max-width: 50%;
      width: -webkit-fill-available;
      padding: 7px 17px 9px 15px;

      .grad-count {
        line-height: 20px;
      }
    }

    @media (max-width: 385px) {
      .price {
        flex-direction: column;

        span {
          padding-top: 5px;
        }
      }
    }
  }

  .classes {
    background-color: white;
    border-radius: 0 0 15px 15px;
    border: 1px solid rgba(40, 60, 87, 0.2);
    min-height: 36px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 2px 15px;

      img {
        height: fit-content;
      }

      .class-chip:not(:last-of-type) {
        border-right: 1px solid rgba(40, 60, 87, 0.1);
      }
    }
  }
}

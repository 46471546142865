@import '../WhatIs';

.what-is.path-score {
  background: radial-gradient(267.71% 93.9% at 50% 5.02%, #ffe5a2 22.98%, #f0f5ff 80.83%);


  .score-panel {
    padding: 30px 0;

    .score-icon {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background-color: #F6C342;

      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  .main-button {
    background-color: #F6C342 !important;
    margin-bottom: 10px;

    &:active {
      background-color: #ddaf3b !important;
    }
  }
}
.signup {
  overflow-y: auto;

  .header {
    padding-top: 35px;
  }

  form {
    .code {
      margin-top: 15px;
      margin-bottom: 20px;
      width: 80%;
      align-self: center;

      input {
        height: 60px;
        text-align: center;

        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #283c57 !important;

        &.invalid {
          color: #c93f12 !important;
        }
      }
    }

    .main-button {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .footer {
    padding-bottom: 10px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    .links {
      gap: 10px;
      padding-top: 20px;

      a {
        font-family: 'Roboto Slab', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #23271F;
      }
    }
  }
}

.test-scores {
  font-family: 'Roboto Slab', serif;
  margin: 10px 0 20px 0;

  p,
  .form-label {
    font-style: normal;
  }

  p {
    padding: 0 10px;
  }

  .form-label {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #000000;
  }

  &--button {
    width: 190px;
    height: 80px;
    background-color: #B0B1B0;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #23271F;
    border: 0;

    &--active,
    &:active,
    &:hover {
      background-color: #F6C342 !important;
      color: #23271F !important;
    }
  }

  &--input {
    margin: 0 auto;
    height: 80px !important;
    border-radius: 10px !important;
    font-style: normal !important;
    line-height: 150% !important;
    text-align: center;
    color: #23271F !important;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: #23271F;
    }

    &--test-score {
      max-width: 388px;
      background-color: #E7E7E7;
      font-weight: 700 !important;
      font-size: 42px !important;
      letter-spacing: 0.08em;
      text-transform: uppercase;

      &:focus {
        background-color: #E7E7E7;
      }
    }

    &--gpa {
      max-width: 190px;
      width: 190px;
      background-color: #B0B1B0;
      font-weight: 600 !important;
      font-size: 36px !important;

      &:focus {
        background-color: #F6C342;
      }

      &--label {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #818380;
      }
    }

    &:disabled {
      background-color: #B0B1B0;
      opacity: unset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &--test-score:invalid,
    &--gpa:invalid {
      border: 2px solid red !important;
    }
  }

  &--gpa-error-text {
    max-width: 190px;
  }
}